.expand-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 16px;
}

.expand-button-span {
    cursor: pointer;
}

.display-linebreak {
    white-space: pre-line;
}

.judul-bacaan-kalender-liturgi {
    font-style: italic;
}

.expand-div {
    border-bottom: 1px solid #657687;
}

.button-toolbar-kalenderliturgi {
    width: 250px;
    font-size: 1em;
    margin: 0 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #aaa;
}

.button-toolbar-kalenderliturgi:hover {
    color: #657687;
    cursor: pointer;
}

.kalenderliturgi-kalender {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 16px;   
}